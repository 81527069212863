import { message } from 'ant-design-vue';
import i18n from '@/locale/i18n';

const checkJump = (newWindow, data, toComment = 0) => {
    if (data.state < 8) {
        if (toComment == 1) {
            if (data.subjectType == 2) {
                message.error(i18n.global.t('bbs.join_circle_can', 1));
                // 加入圈子才能回答哦！
            } else {
                message.error(i18n.global.t('bbs.join_circle_can', 2));
                // 加入圈子才能评论哦！
            }
        } else {
            message.error(i18n.global.t('bbs.join_circle_view_detail'));
            // 加入圈子才能查看主题详情！
        }
    } else {
        let url = `/bbs/detail?groupId=${data.groupId}&subjectId=${data.subjectId}&toComment=${toComment}`;
        if (newWindow) {
            window.open(url + '&ddtab=true');
        } else {
            window.location.href = url;
        }
    }
}

export default checkJump;